import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchAllCharacteristics = async () => {
    try {
        let apiUrl = `${BASE_URL}/characteristic`;
        const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });
        if (!response.ok) throw new Error("Failed to fetch categories");
        const data = await response.json();
        return data.data;
    }
    catch (err) {
        throw err;
    }
}

export const getAllCharacteristicsWithPagination = async (
    value,
    page,
    pageSize
) => {
    try {
        let apiUrl = `${BASE_URL}/characteristic/getAllCharacteristicsWithPagination?`;
        if (value) apiUrl += `value=${value}&`;
        if (page) apiUrl += `page=${page}&`;
        if (pageSize) apiUrl += `pageSize=${pageSize}`;
        const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });
        if (!response.ok) throw new Error("Failed to fetch categories");
        const data = await response.json();
        return data.data;
    }
    catch (err) {
        throw err;
    }
}

export const getCharacteristicByIdApi = async (id) => {
    try {
        const response = await fetchWithErrorHandling(
            `${BASE_URL}/characteristic/${id}`,
            { method: "GET" }
        );

        if (!response.ok) throw new Error("Failed to add product");
        const data = await response.json();
        return data.data;
    } catch (error) {
        throw error;
    }
};

export const addCharacteristic = async (value) => {
    try {
        const response = await fetchWithErrorHandling(`${BASE_URL}/characteristic`, {
            method: "POST",
            body: JSON.stringify(value),
        });

        if (!response.ok) throw new Error("Failed to add Category");
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};