import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllOrdersAsync } from "../../store/slices/orderSlice";

import withToast from "../../hocs/withToast";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import Loader from "../../components/Loader";


import { BASE_URL } from "../../api/helper";

const columns = [
  { id: "productImage", label: "Image", minWidth: 50, maxWidth: 100, align: "left" },
  { id: "productName", label: "Product", minWidth: 30, maxWidth: 50, align: "left" },
  { id: "name", label: "Name", minWidth: 30, maxWidth: 50, align: "left" },
  { id: "email", label: "Email", minWidth: 110, align: "left" },
  { id: "address", label: "Address", minWidth: 150, align: "left" },
  { id: "createdAt", label: "Order Date", minWidth: 50, align: "left" }
];

const OrderManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    orders = [],
    totalCount,
    loading,
  } = useSelector((state) => state.orders);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(
      fetchAllOrdersAsync({
        value: filterValue,
        page,
        pageSize,
      })
    );
  }, [filterValue, page, pageSize, lastListLoadTime]);


  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const rowsWithAction = (rows) => {
    return rows?.orders?.map((e) => ({
      ...e,
      address: <span>{e.street}, {e.city}, {e.state}, {e.country}</span>,
      name: <span>{e.first_name} {e.last_name}</span>,
      createdAt: <span>{e.createdAt}</span>,
      productName: <span>{e.name}</span>,
      createdAt: <span>{new Date(e.created_at).toLocaleString('en-US', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })}</span>,
      productImage: e.images ? (
        <img
          src={BASE_URL + "/doc/i/" + e?.images[0]?.image_path}
          alt={e.name}
          height={50}
          style={{ width: "100px" }}
        />
      ) : (
        "No Image"
      ),
    }));
  };

  return (
    <div>
      <h3>Orders</h3>
      <div>
        <GenericFilter
          searchCb={(e) => setFilterValue(e.target.value)}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(orders)}
        rowCount={totalCount}
        cb={updatePageSetings}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(OrderManagement);
