import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { IconButton } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  fetchProductsAsync,
  fetchProductByIdAsync,
  resetState,
  updateProductByIdAsync,
} from "../../store/slices/productSlice";
import { fetchAllCategoriesAsync } from "../../store/slices/catergorySlice";
import { fetchAllSubCategoriesAsync } from "../../store/slices/subCategorySlice";
import { fetchAllCharacteristicsAsync } from "../../store/slices/characteristicSlice";

import withToast from "../../hocs/withToast";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import DropdownFilter from "../../components/DropDownFilter";
import GenericModal from "../../components/Modal";
import ProductForm from "./ProductForm";
import Loader from "../../components/Loader";
import CustomChip from "../../components/CustomChip";

import { BASE_URL } from "../../api/helper";

import { RiEdit2Fill } from "react-icons/ri";
import { IoBagRemoveOutline } from "react-icons/io5";
import { IoBagCheckOutline } from "react-icons/io5";

const columns = [
  { id: "image", label: "Image", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 100 },
  { id: "category_name", label: "Category", minWidth: 100, align: "center" },
  {
    id: "subcategory_name",
    label: "SubCategory",
    minWidth: 100,
    align: "center",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
    align: "left",
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 50,
    align: "center",
  },
  {
    id: "size",
    label: "Size",
    minWidth: 50,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 50,
    align: "center",
  },
];

const ProductManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    products = [],
    totalCount,
    loading,
  } = useSelector((state) => state.products);
  const { allCategories = [] } = useSelector((state) => state.categories);
  const { allSubCategories = [] } = useSelector((state) => state.subCategories);
  const [filterValue, setFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());

  useEffect(() => {
    dispatch(fetchAllSubCategoriesAsync());
    dispatch(fetchAllCategoriesAsync());
    dispatch(fetchAllCharacteristicsAsync());
  }, []);

  useEffect(() => {
    dispatch(
      fetchProductsAsync({
        value: filterValue,
        status: statusFilter,
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        page,
        pageSize,
      })
    );
  }, [
    filterValue,
    statusFilter,
    selectedCategory,
    selectedSubcategory,
    page,
    pageSize,
    lastListLoadTime,
  ]);

  const handleStatusChange = (data) => {
    dispatch(updateProductByIdAsync(data))
      .unwrap()
      .then((_) => {
        notify("Product updated successfully", "success");
        setLastListLoadTime(new Date());
      })
      .catch((err) => {
        console.error(err);
        notify(err.message || "Product not found", "error");
      });
  };

  const handleProductModal = (show, reloadList = false) => {
    setShowAddProductModal(show);
    if (reloadList) setLastListLoadTime(new Date());
  };

  const startEdit = (id) => {
    if (!id) {
      dispatch(resetState());
      handleProductModal(true, false);
    } else {
      dispatch(fetchProductByIdAsync(id))
        .unwrap()
        .then((_) => {
          handleProductModal(true, false);
        })
        .catch((err) => {
          console.error(err);
          notify(err.message || "Product not found", "error");
        });
    }
  };

  const renderActionButtons = (id, status) => {
    return (
      <div className="d-flex">
        <IconButton
          color="primary"
          aria-label="edit"
          className="mx-1"
          title="Edit Product"
          onClick={() => {
            startEdit(id);
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          <RiEdit2Fill fontSize="inherit" />
        </IconButton>
        <IconButton
          color="primary"
          className="mx-1"
          aria-label="status"
          title={status ? "Inactivate" : "Activate"}
          onClick={() => {
            handleStatusChange({ id, status: !status });
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          {status ? (
            <IoBagRemoveOutline fontSize="x-large" color="#d32f2f" />
          ) : (
            <IoBagCheckOutline fontSize="x-large" color="#2e7d32" />
          )}
        </IconButton>
      </div>
    );
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      subcategory_name: e.subcategory_name || "",
      action: renderActionButtons(e.id, e.is_active),
      is_active: (
        <CustomChip
          label={e.is_active ? "Active" : "Inactive"}
          color={e.is_active ? "success" : "error"}
          className={"px-3"}
        />
      ),
      image: e.images?.length ? (
        <img
          src={BASE_URL + "/doc/i/" + e?.images[0]?.image_path}
          alt={e.name}
          height={50}
          style={{ width: "100px" }}
        />
      ) : null,
    }));
  };

  const filters = () => {
    const subCategoryOprions = !!selectedCategory
      ? allSubCategories.filter((ele) => ele.category_id === selectedCategory)
      : allSubCategories;
    return (
      <>
        <Col xs={6} md={2}>
          <DropdownFilter
            options={allCategories.map((e) => ({ label: e.name, id: e.id }))}
            onChange={(e) =>
              setSelectedCategory(
                allCategories.find((ele) => ele.name === e.target.innerHTML)?.id
              )
            }
            label={"Category"}
          />
        </Col>
        <Col xs={6} md={2}>
          <DropdownFilter
            options={subCategoryOprions.map((e) => ({
              label: e.name,
              id: e.id,
            }))}
            onChange={(e) =>
              setSelectedSubcategory(
                subCategoryOprions.find(
                  (ele) => ele.name === e.target.innerHTML
                )?.id
              )
            }
            label={"Sub Categories"}
          />
        </Col>
      </>
    );
  };

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  return (
    <div>
      <h3>Product Management</h3>
      <div>
        <GenericFilter
          filters={filters()}
          searchCb={(e) => setFilterValue(e.target.value)}
          statusLabel={"Status..."}
          dropdownCb={(e) => setStatusFilter(e)}
          includeStatusFilter={true}
          addCb={() => startEdit(0)}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(products)}
        rowCount={totalCount}
        cb={updatePageSetings}
        addCb={() => startEdit(0)}
      />
      {showAddProductModal && (
        <GenericModal
          modalShow={showAddProductModal}
          modalClose={() => handleProductModal(false, false)}
          modalHeader={"Product Details"}
          modalBody={
            <ProductForm modalClose={() => handleProductModal(false, true)} />
          }
          modalAlign={true}
          size={"xl"}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(ProductManagement);
