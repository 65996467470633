import React, { useLayoutEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GiHamburgerMenu } from "react-icons/gi";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { accountAsync } from "../../store/slices/authSlice";

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Dashboard from "../../features/Dashboard/Dashboard";
import ProductManagement from "../../features/Product";
import CategoryManagement from "../../features/Category";
import SubCategoryManagement from "../../features/Category/SubCategory";
import CharacteristicsManagement from "../../features/Characteristics";
import OrderManagement from "../../features/Order";
import Users from "../../features/User";
import Loader from "../../components/Loader";
import withNavigate from "../../hocs/withNavigate";
import withToast from "../../hocs/withToast";

const Layout = ({ navigate }) => {
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    checkAndFetchAccount();
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [isAuthenticated]);

  const checkAndFetchAccount = () => {
    if (isAuthenticated && user && !user.first_name) {
      dispatch(accountAsync())
        .unwrap()
        .then(() => {
          console.info("Account feched successfully");
        })
        .catch((err) => {
          console.error("Account fetch failed", err);
        });
    }
    if (!isAuthenticated) {
      navigate("/login");
    }
  };

  const renderProtectedRoutes = () => {
    return (
      <Routes>
        <Route path="products" element={<ProductManagement />} />
        <Route path="sub-categories" element={<SubCategoryManagement />} />
        <Route path="categories" element={<CategoryManagement />} />
        <Route path="characteristics" element={<CharacteristicsManagement />} />
        <Route path="orders" element={<OrderManagement />} />
        <Route path="users" element={<Users />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    );
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div className="d-flex">
      {size > 500 && <Sidebar />}

      {/* Main Content */}
      <div className="w-100 m-sm-2">
        <div className="d-flex">
          {size < 500 && (
            <>
              <Button variant="text" onClick={toggleDrawer(true)}>
                <GiHamburgerMenu />
              </Button>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                <Sidebar />
              </Drawer>
            </>
          )}
          <Navbar />
        </div>
        <Container
          fluid
          className="p-4"
          style={{
            backgroundColor: "#f8f9fa",
            width: "100%",
            borderRadius: 10,
          }}
        >
          {renderProtectedRoutes()}
        </Container>
      </div>
      <Loader loading={loading} />
    </div>
  );
};
export default withToast(withNavigate(Layout));
