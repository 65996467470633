import React, { useState } from "react";
import { Nav, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { BiSolidCategoryAlt } from "react-icons/bi";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaHome,
  FaBox,
  FaUsers,
  FaShapes,
} from "react-icons/fa";

import "./style.css";

const Sidebar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => setCollapsed(!collapsed);
  const checkIfLinkIsActive = (pathname) => {
    if (
      pathname === "/dashboard" &&
      (location.pathname === "" || location.pathname === "/")
    )
      return true;
    return pathname === location.pathname;
  };

  return (
    <div
      className={`d-flex flex-column position-sticky sidebar ${
        collapsed ? "sidebar-collapsed" : ""
      }`}
    >
      <div className="d-flex flex-row w-100">
        <div className={"logo-container"}>
          <img
            src={
              collapsed
                ? "./assets/images/logo-sm.png"
                : "./assets/images/logo.png"
            }
            className="sidebar-logo"
            alt="logo"
          />
        </div>
        <div className="mt-3 floating-anchor">
          <Button variant="link" onClick={toggleCollapse}>
            {collapsed ? (
              <FaChevronCircleRight size={25} color="#4c4d9d" />
            ) : (
              <FaChevronCircleLeft size={25} color="#4c4d9d" />
            )}
          </Button>
        </div>
      </div>
      <Nav className="flex-column">
        <Nav.Link
          as={Link}
          to="/dashboard"
          className={`d-flex align-items-center justify-sm-content-center ${
            checkIfLinkIsActive("/dashboard") ? "active" : ""
          }`}
        >
          <FaHome
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Dashboard"}</div>
        </Nav.Link>

        <Nav.Link
          as={Link}
          to="/categories"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/categories") ? "active" : ""
          }`}
        >
          <BiSolidCategoryAlt
            className="sidebarIcon"
            style={
              collapsed ? { height: 25, width: 25 } : { height: 20, width: 20 }
            }
          />
          <div className="ms-3 sidebarIcon">
            {!collapsed && "Main Categories"}
          </div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/sub-categories"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/sub-categories") ? "active" : ""
          }`}
        >
          <FaShapes
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">
            {!collapsed && "Sub Categories"}
          </div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/characteristics"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/characteristics") ? "active" : ""
          }`}
        >
          <BiSolidCategoryAlt
            className="sidebarIcon"
            style={
              collapsed ? { height: 25, width: 25 } : { height: 20, width: 20 }
            }
          />
          <div className="ms-3 sidebarIcon">
            {!collapsed && "Characteristics"}
          </div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/products"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/products") ? "active" : ""
          }`}
        >
          <FaBox
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Products"}</div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/orders"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/orders") ? "active" : ""
          }`}
        >
          <FaBox
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Orders"}</div>
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/users"
          className={`d-flex align-items-center ${
            checkIfLinkIsActive("/users") ? "active" : ""
          }`}
        >
          <FaUsers
            className="sidebarIcon"
            style={collapsed && { height: 20, width: 20 }}
          />
          <div className="ms-3 sidebarIcon">{!collapsed && "Users"}</div>
        </Nav.Link>
      </Nav>
      <div className="mt-auto p-3 text-body-tertiary text-center">
        {collapsed ? "© UE" : "© Universal Exports"}
      </div>
    </div>
  );
};

export default Sidebar;
