import { BASE_URL, fetchWithErrorHandling } from "./helper";

export const fetchAllOrders = async (
  value,
  page,
  pageSize
) => {
  try {
    let apiUrl = `${BASE_URL}/order/getAllOrderWithPagination?`;
    if (value) apiUrl += `value=${value}&`;
    if (page) apiUrl += `page=${page}&`;
    if (pageSize) apiUrl += `pageSize=${pageSize}`;

    const response = await fetchWithErrorHandling(apiUrl, { method: "GET" });
    if (!response.ok) throw new Error("Failed to fetch Order");
    const data = await response.json();
    return data.data;
  } catch (error) {
    throw error;
  }
};