import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";

import {
  resetState,
  fetchCategoryByIdAsync,
} from "../../store/slices/catergorySlice";
import { fetchCategoriesAsync } from "../../store/slices/catergorySlice";

import withToast from "../../hocs/withToast";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import GenericModal from "../../components/Modal";
import CategoryForm from "./CategoryForm";
import Loader from "../../components/Loader";
import CustomChip from "../../components/CustomChip";

import { BASE_URL } from "../../api/helper";
import { CategoryValidation } from "./CategoryValidation";
import { StatusOptions } from "../../utils/constants";

import { TiTick } from "react-icons/ti";
import { RiEdit2Fill } from "react-icons/ri";

const columns = [
  { id: "image", label: "Image", minWidth: 30, maxWidth: 50, align: "left" },
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  {
    id: "is_main",
    label: "Main",
    minWidth: 70,
    align: "center",
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 50,
    maxWidth: 50,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const CategoryManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    categories = [],
    totalCount,
    loading,
  } = useSelector((state) => state.categories);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(
      fetchCategoriesAsync({
        value: filterValue,
        status: statusFilter,
        page,
        pageSize,
      })
    );
  }, [filterValue, statusFilter, page, pageSize, lastListLoadTime]);

  const handleCategoryModal = (show, reloadList = false) => {
    setShowAddCategoryModal(show);
    if (reloadList) setLastListLoadTime(new Date());
  };

  const renderActionButtons = (data) => {
    return (
      <div className="d-flex justify-content-center">
        <IconButton
          color="primary"
          aria-label="edit"
          onClick={() => {
            startEdit(data);
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          <RiEdit2Fill fontSize="inherit" />
        </IconButton>
      </div>
    );
  };

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const startEdit = (id) => {
    if (!id) {
      dispatch(resetState());
      handleCategoryModal(true, false);
    } else {
      dispatch(fetchCategoryByIdAsync(id))
        .unwrap()
        .then((_) => {
          handleCategoryModal(true, false);
        })
        .catch((err) => {
          console.error(err);
          notify(err.message || "Category not found", "error");
        });
    }
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      action: renderActionButtons(e.id),
      is_main: e.is_main && <TiTick fontSize="x-large" />,
      is_active: (
        <CustomChip
          label={e.is_active ? "Active" : "Inactive"}
          color={e.is_active ? "success" : "error"}
          className={"px-3"}
        />
      ),
      image: e.image_url ? (
        <img
          src={BASE_URL + "/doc/i/" + e.image_url}
          alt={e.name}
          height={50}
          style={{ width: "100px" }}
        />
      ) : (
        "No Image"
      ),
    }));
  };

  return (
    <div>
      <h3>Categories</h3>
      <div>
        <GenericFilter
          searchCb={(e) => setFilterValue(e.target.value)}
          addCb={() => startEdit(0)}
          statusLabel={"Status..."}
          dropdownCb={(e) => setStatusFilter(e)}
          includeStatusFilter={true}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(categories)}
        rowCount={totalCount}
        cb={updatePageSetings}
        addCb={() => startEdit(0)}
      />
      {showAddCategoryModal && (
        <GenericModal
          modalShow={showAddCategoryModal}
          modalClose={() => handleCategoryModal(false, false)}
          modalHeader={"Category Details"}
          modalBody={
            <CategoryForm
              CategoryValidation={CategoryValidation}
              modalClose={() => handleCategoryModal(false, true)}
            />
          }
          size="lg"
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(CategoryManagement);
