import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";

import withToast from "../../../hocs/withToast";
import GenericTable from "../../../components/GenricTable";
import GenericFilter from "../../../components/GenericFilter";
import DropdownFilter from "../../../components/DropDownFilter";
import Loader from "../../../components/Loader";
import GenericModal from "../../../components/Modal";
import SubCategoryForm from "./SubCategoryForm";
import CustomChip from "../../../components/CustomChip";

import { BASE_URL } from "../../../api/helper";
import {
  resetState,
  fetchSubCategoriesAsync,
  fetchSubCategoryByIdAsync,
} from "../../../store/slices/subCategorySlice";

import { RiEdit2Fill } from "react-icons/ri";

const columns = [
  { id: "image", label: "Image", minWidth: 30, maxWidth: 50, align: "left" },
  { id: "name", label: "Name", minWidth: 150, align: "left" },
  { id: "category_name", label: "Category Name", minWidth: 150, align: "left" },
  {
    id: "is_active",
    label: "Status",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const SubCategoryManagement = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    subCategories = [],
    totalCount,
    loading,
  } = useSelector((state) => state.subCategories);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { categories = [] } = useSelector((state) => state.categories);
  useEffect(() => {
    dispatch(
      fetchSubCategoriesAsync({
        value: filterValue,
        status: statusFilter,
        category: selectedCategory,
        page,
        pageSize,
      })
    );
  }, [
    filterValue,
    statusFilter,
    selectedCategory,
    page,
    pageSize,
    lastListLoadTime,
  ]);

  const handleCategoryModal = (show, reloadList = false) => {
    setShowAddCategoryModal(show);
    if (reloadList) setLastListLoadTime(new Date());
  };

  const renderActionButtons = (data) => {
    return (
      <div className="d-flex justify-content-center">
        <IconButton
          color="primary"
          aria-label="edit"
          onClick={() => {
            startEdit(data);
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          <RiEdit2Fill fontSize="inherit" />
        </IconButton>
      </div>
    );
  };

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const startEdit = (id) => {
    if (!id) {
      dispatch(resetState());
      handleCategoryModal(true, false);
    } else {
      dispatch(fetchSubCategoryByIdAsync(id))
        .unwrap()
        .then((_) => {
          handleCategoryModal(true, false);
        })
        .catch((err) => {
          console.error(err);
          notify(err.message || "Category not found", "error");
        });
    }
  };

  const filters = () => {
    return (
      <>
        <Col xs={6} md={3}>
          <DropdownFilter
            options={categories.map((e) => ({ label: e.name, id: e.id }))}
            onChange={(e) =>
              setSelectedCategory(
                categories.find((ele) => ele.name === e.target.innerHTML)?.id
              )
            }
            label={"Category"}
          />
        </Col>
        <Col xs={0} md={1}></Col>
      </>
    );
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      action: renderActionButtons(e.id),
      is_active: (
        <CustomChip
          label={e.is_active ? "Active" : "Inactive"}
          color={e.is_active ? "success" : "error"}
          className={"px-3"}
        />
      ),
      image: e.image_url ? (
        <img
          src={BASE_URL + "/doc/i/" + e.image_url}
          alt={e.name}
          height={50}
          style={{ width: "100px" }}
        />
      ) : (
        "No Image"
      ),
    }));
  };

  return (
    <div>
      <h3>Sub-Categories</h3>
      <div>
        <GenericFilter
          filters={filters()}
          searchCb={(e) => {
            setFilterValue(e.target.value);
          }}
          addCb={() => startEdit(0)}
          statusLabel={"Status..."}
          dropdownCb={(e) => setStatusFilter(e)}
          includeStatusFilter={true}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(subCategories)}
        rowCount={totalCount}
        cb={updatePageSetings}
        addCb={() => startEdit(0)}
      />
      {showAddCategoryModal && (
        <GenericModal
          modalShow={showAddCategoryModal}
          modalClose={() => handleCategoryModal(false, false)}
          modalHeader={"Sub Category Details"}
          modalBody={
            <SubCategoryForm
              modalClose={() => handleCategoryModal(false, true)}
            />
          }
          size="lg"
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(SubCategoryManagement);
