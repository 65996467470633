import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addProductAsync } from "../../store/slices/productSlice";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  Button as ButtonMui,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import { saveProductImage } from "../../store/slices/productSlice";
import Loader from "../../components/Loader";
import withToast from "../../hocs/withToast";
import Dropzone from "../../components/DropZone";
import { ProductValidation } from "./ProductValidation";
import { BASE_URL } from "../../api/helper";

const ProductForm = ({ notify, modalClose }) => {
  const dispatch = useDispatch();
  const { loading, currentProduct } = useSelector((state) => state.products);
  const { allCategories = [] } = useSelector((state) => state.categories);
  const { allSubCategories = [] } = useSelector((state) => state.subCategories);
  const { characteristics = [] } = useSelector((state) => state.characteristics);
  const [showFileDrop, setShowFileDrop] = useState(
    currentProduct?.productImagePending ? true : false
  );
  const [currentCategory, setCurrentCategory] = useState(
    currentProduct?.category_id ?? ""
  );

  const [currentCharacteristics, setCurrentCharacteristics] = useState(
    currentProduct?.characteristics
      ? currentProduct.characteristics.map((ele) => ({
          id: ele.characteristic_id,
          name: ele.characteristic_name,
        }))
      : []
  );

  const getImagesWithPreview = () => {
    return (
      currentProduct?.images?.map((e) => ({
        ...e,
        preview: `${BASE_URL}/doc/i/${e.image_path}`,
      })) || []
    );
  };

  const [productImages, setProductImages] = useState(getImagesWithPreview());

  const formik = useFormik({
    initialValues: {
      name: currentProduct?.name || "",
      description: currentProduct?.description || "",
      size: currentProduct?.size || "",
      category_id: currentProduct?.category_id || null,
      subcategory_id: currentProduct?.subcategory_id || null,
      id: currentProduct?.id || 0,
      images: currentProduct?.images || [],
      characteristics: currentProduct?.characteristics || [],
    },
    validationSchema: ProductValidation,
    onSubmit: (values) => {
      dispatch(
        addProductAsync({
          ...values,
          id: currentProduct?.id || 0,
          images: productImages,
          characteristics: values.characteristics.map(
            (ele) => ele?.id || ele?.characteristic_id
          ),
        })
      )
        .unwrap()
        .then((data) => {
          notify("Product Saved", "success");
          setShowFileDrop(data.productImagePending);
          if (currentProduct?.id) modalClose();
        })
        .catch((err) => {
          notify(err.message || "Failed to save product", "error");
        });
    },
  });

  const handleImage = (images) => {
    const formData = new FormData();
    images.forEach((file) => {
      formData.append("image", file);
    });
    dispatch(saveProductImage({ id: currentProduct.id, image: formData }))
      .unwrap()
      .then((data) => {
        setProductImages([
          ...productImages,
          ...data.images.map((imagePath) => ({
            image_path: imagePath,
            preview: `${BASE_URL}/doc/i/${imagePath}`,
          })),
        ]);
      })
      .catch((err) => {
        console.error({ err });
        notify(err.message || "Failed to upload image", "error");
      });
  };

  const removeImage = (file) => {
    setProductImages(
      productImages.filter((ele) => ele.image_path !== file.image_path)
    );
  };

  return (
    <>
      {!showFileDrop && (
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column align-items-center"
        >
          <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
            <OutlinedInput
              id="outlined-adornment-name"
              type={"text"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              name={"name"}
              label="Name"
            />
          </FormControl>
          <div className="w-100 mt-2">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-size">
                    Choose Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.category_id}
                    label="Choose Category"
                    name="category_id"
                    onChange={(op) => {
                      formik.setFieldValue("category_id", op.target.value);
                      setCurrentCategory(op.target.value);
                    }}
                  >
                    {allCategories.map((ele, index) => (
                      <MenuItem value={ele.id} key={`categories_idx${index}`}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-subcategory_id">
                    Choose Sub Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.subcategory_id}
                    label="Choose Sub Category"
                    name="subcategory_id"
                    onChange={(op) =>
                      formik.setFieldValue("subcategory_id", op.target.value)
                    }
                    disabled={!currentCategory}
                  >
                    {allSubCategories
                      .filter((e) => e.category_id === currentCategory)
                      .map((ele, index) => (
                        <MenuItem
                          value={ele.id}
                          key={`sub-categories_idx${index}`}
                        >
                          {ele.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <label className="text-danger">
                    {!currentCategory && "Please Choose the Category first!"}
                  </label>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className="w-100 mt-3 mb-3">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-size">
                    Size
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-size"
                    type={"text"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.size}
                    name={"size"}
                    label="Size"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-Characteristics">
                    Choose Characteristics
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    renderValue={(selected) =>
                      selected.map((ele) => ele.name).join(", ")
                    }
                    value={currentCharacteristics}
                    label="Choose Characteristics"
                    name="characteristics"
                    input={<OutlinedInput label="Choose Characteristics" />}
                    onChange={(op) => {
                      formik.setFieldValue("characteristics", op.target.value);
                      setCurrentCharacteristics(op.target.value);
                    }}
                  >
                    {characteristics.map((ele, index) => (
                      <MenuItem value={ele} key={`sub-categories_idx${index}`}>
                        <Checkbox
                          checked={currentCharacteristics.find(
                            (item) => item.id === ele.id
                          )}
                        />
                        <ListItemText primary={ele.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <FormControl sx={{ m: 2, width: "100%" }} variant="outlined">
            <TextField
              id="outlined-adornment-description"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              name="description"
              label="Description"
              multiline
              minRows={4}
              variant="outlined"
            />
          </FormControl>
          {currentProduct?.id && (
            <Dropzone
              currentImages={productImages}
              saveImage={handleImage}
              removeImage={removeImage}
              multiple={true}
              fileUploadlabel={"Product images"}
            />
          )}
          <div className="mt-2 text-start position-sticky bottom-0">
            <ButtonMui
              variant="contained"
              disabled={!formik.isValid}
              size="large"
              type="submit"
            >
              Save Product
            </ButtonMui>
          </div>
          <Loader loading={loading} />
        </form>
      )}
      {showFileDrop && (
        <form onSubmit={formik.handleSubmit}>
          <h5>Upload Images</h5>
          <Dropzone
            currentImages={productImages}
            saveImage={handleImage}
            removeImage={removeImage}
            multiple={true}
            fileUploadlabel={"Product images"}
          />
          <div className="mt-2 text-center">
            <ButtonMui
              variant="contained"
              disabled={!formik.isValid}
              size="large"
              type="submit"
            >
              Save Product
            </ButtonMui>
          </div>
        </form>
      )}
    </>
  );
};

export default withToast(ProductForm);
