import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";

import { getAllCharacteristicsWithPaginationAsync, fetchCharacteristicByIdAsync, resetState, } from "../../store/slices/characteristicSlice";

import withToast from "../../hocs/withToast";
import GenericTable from "../../components/GenricTable";
import GenericFilter from "../../components/GenericFilter";
import GenericModal from "../../components/Modal";
import CharacteristicsForm from "./CharacteristicsForm";
import Loader from "../../components/Loader";
import { RiEdit2Fill } from "react-icons/ri";

const columns = [
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  {
    id: "action",
    label: "Actions",
    minWidth: 40,
    align: "center",
  },
];

const Characteristics = ({ notify }) => {
  const dispatch = useDispatch();
  const {
    characteristicList = [],
    totalCount,
    loading,
  } = useSelector((state) => state.characteristics);
  const [showAddcharacteristicsModal, setShowAddcharacteristicsModal] = useState(false);
  const [lastListLoadTime, setLastListLoadTime] = useState(new Date());
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        dispatch(
            getAllCharacteristicsWithPaginationAsync({
                value: filterValue,
                page,
                pageSize,
            })
        );
    }, [filterValue, page, pageSize, lastListLoadTime]);

  const handleCharactristicModal = (show, reloadList = false) => {
    setShowAddcharacteristicsModal(show);
    if (reloadList) setLastListLoadTime(new Date());
  };

  const renderActionButtons = (data) => {
    return (
      <div className="d-flex justify-content-center">
        <IconButton
          color="primary"
          aria-label="edit"
          onClick={() => {
            startEdit(data);
          }}
          size="large"
          sx={{ padding: 0 }}
        >
          <RiEdit2Fill fontSize="inherit" />
        </IconButton>
      </div>
    );
  };

  const updatePageSetings = (_page, _rowsPerPage) => {
    if (_page !== page) setPage(_page);
    if (_rowsPerPage !== pageSize) setPageSize(_rowsPerPage);
  };

  const startEdit = (id) => {
    if (!id) {
      dispatch(resetState());
      handleCharactristicModal(true, false);
    } else {
      dispatch(fetchCharacteristicByIdAsync(id))
        .unwrap()
        .then((_) => {
          handleCharactristicModal(true, false);
        })
        .catch((err) => {
          console.error(err);
          notify(err.message || "Charactristic not found", "error");
        });
    }
  };

  const rowsWithAction = (rows) => {
    return rows.map((e) => ({
      ...e,
      action: renderActionButtons(e.id)
    }));
  };

  return (
    <div>
      <h3>characteristics</h3>
      <div>
        <GenericFilter
          searchCb={(e) => setFilterValue(e.target.value)}
          addCb={() => startEdit(0)}
        />
      </div>
      <GenericTable
        columns={columns}
        rows={rowsWithAction(characteristicList)}
        rowCount={totalCount}
        cb={updatePageSetings}
        addCb={() => startEdit(0)}
      />
      {showAddcharacteristicsModal && (
        <GenericModal
          modalShow={showAddcharacteristicsModal}
          modalClose={() => handleCharactristicModal(false, false)}
          modalHeader={"Category Details"}
          modalBody={
            <CharacteristicsForm
              modalClose={() => handleCharactristicModal(false, true)}
            />
          }
          size="lg"
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default withToast(Characteristics);
