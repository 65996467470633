import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addCharacteristic, fetchAllCharacteristics, getCharacteristicByIdApi, getAllCharacteristicsWithPagination } from '../../api/characteristic'

export const fetchAllCharacteristicsAsync = createAsyncThunk(
  "characteristics/fetchAllCharacteristicsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const characteristics = await fetchAllCharacteristics();
      return characteristics;
    }
    catch (err) {
      rejectWithValue(err);
    }
  })


export const getAllCharacteristicsWithPaginationAsync = createAsyncThunk(
  "characteristics/getAllCharacteristicsWithPaginationAsync",
  async ({value, page, pageSize}, { rejectWithValue }) => {
    try {
      const characteristics = await getAllCharacteristicsWithPagination(value, page + 1, pageSize);
      return characteristics;
    }
    catch (err) {
      rejectWithValue(err);
    }
  })

export const fetchCharacteristicByIdAsync = createAsyncThunk(
  "categories/fetchCharacteristicByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const characteristic = await getCharacteristicByIdApi(id);
      return characteristic;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error);
    }
  }
);

export const addCharacteristicAsync = createAsyncThunk(
  "characteristics/addCharacteristicAsync",
  async (data, { rejectWithValue }) => {
    try {
      const characteristic = await addCharacteristic(data);
      return characteristic;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

const characteristicSlice = createSlice({
  name: "characteristics",
  initialState: {
    characteristics: [],
    characteristicList: [],
    loading: false,
    totalCount: 0,
    error: null,
    currentCharacteristic: null
  },
  reducers: {
    resetState: (state) => {
      state.currentCharacteristic = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCharacteristicsAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchAllCharacteristicsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.characteristics = action.payload;
        state.totalCount = action.payload?.totalCount;
      })
      .addCase(fetchAllCharacteristicsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllCharacteristicsWithPaginationAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllCharacteristicsWithPaginationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.characteristicList = action.payload.characteristics;
        state.totalCount = action.payload?.totalCount;
      })
      .addCase(getAllCharacteristicsWithPaginationAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCharacteristicAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addCharacteristicAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCharacteristic = action.payload;
      })
      .addCase(addCharacteristicAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCharacteristicByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCharacteristicByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCharacteristic = action.payload;
      })
      .addCase(fetchCharacteristicByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetState } = characteristicSlice.actions;
export default characteristicSlice.reducer;