import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  Button as ButtonMui
} from "@mui/material";
import Loader from "../../components/Loader";
import withToast from "../../hocs/withToast";
import { addCharacteristicAsync } from "../../store/slices/characteristicSlice";

const CharacteristicsForm = ({ notify, modalClose }) => {
  const dispatch = useDispatch();
  const { loading, currentCharacteristic } = useSelector((state) => state.characteristics);

  const formik = useFormik({
    initialValues: {
      name: currentCharacteristic?.name || "",
      id: currentCharacteristic?.id || 0,
    },
    onSubmit: (values) => {
      dispatch(addCharacteristicAsync({name: values.name}))
        .unwrap()
        .then((res) => {
          notify("characteristics Saved", "success");
          if (res?.id) modalClose();
        })
        .catch((err) => {
          notify(err.message || "Failed to save characteristics", "error");
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="d-flex flex-column align-items-center"
    >
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
        <OutlinedInput
          id="outlined-adornment-name"
          type={"text"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name={"name"}
          label="Name"
        />
      </FormControl>
      <div className="mt-2 text-center abracadbra">
        <ButtonMui
          variant="contained"
          disabled={!formik.isValid}
          size="large"
          type="submit"
        >
          Save characteristics
        </ButtonMui>
      </div>
      <Loader loading={loading} />
    </form>
  );
};
export default withToast(CharacteristicsForm);
