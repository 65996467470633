import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllOrders } from '../../api/order'

export const fetchAllOrdersAsync = createAsyncThunk(
  "orders/fetchAllOrdersAsync",
  async ({ value, page, pageSize }, { rejectWithValue }) => {
    try {
      const orders = await fetchAllOrders(value, page + 1, pageSize);
      return orders;
    }
    catch (err) {
      rejectWithValue(err);
    }
  })

const orderSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    loading: false,
    totalCount: 0,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrdersAsync.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchAllOrdersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
        state.totalCount = action.payload?.totalCount;
      })
      .addCase(fetchAllOrdersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;